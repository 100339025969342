.TicketSection {
  background-color: #FFFFFF;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

.InnerFlex {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 16px;
}

.OuterFlex {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-right: 16px;
}


.DropdownStyle {
  height: 36px;
  width: 100%;
}

.DateTimeStyle {
  height: 36px;
  width: 100%;
}

.TextInputStyle {
  height: 30px;
  width: calc(100% - 8px);
}

.BillingItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.2px;

  color: #111111;
}

.BillingItemValue {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.2px;
  flex-shrink: 0;

  color: #165BC1;
}

.Plus {
  color: #165BC1;
  width: 16px
}

.BillingItemFlex {
  width: 300px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 16px;
}

.HalfWidth {
  width: 100%;
}

.TicketCommentBox {
  padding: 8px;
  background-color: #CEDCF070;
  max-width: 505px;
  object-fit: cover;
  border-radius: 8px;
  justify-self: right;
  justify-content: right;
  justify-items: right;
}

.TicketCommentImage {
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 4px;
  border-radius: 50%;
  object-fit: cover;
  pointer-events: none;
  user-select: none;
}

.TicketCommentContainer {
  width: 100%;
  min-height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.CommentBox {
  width: 100%;
  height: 130px;
  display: flex;

  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: 5px;
  background-color: #FFFFFF;

  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  margin-bottom: 16px;
}

.CommentTextArea {
  width: 99%;
  height: 100%;
  resize: none;
  border-width: 0px;
  background-color: #FFFFFF00;
}

.CommentTextArea:focus {
  outline: 0;
}

.AddCommentButton {
  color: #165BC1;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 20px;
  /* identical to box height, or 154% */

  text-align: center;
  letter-spacing: 0.1px;

  /* Accent / Default */

  color: #165BC1;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  cursor: pointer;
  user-select: none;
}

.ActiveButton {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  background-color: #165BC1; 
  color: white;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 4px 10px rgba(21, 76, 158, 0.24);
  
  width: 50%;
  justify-content: center;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}

.InactiveButton {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  color: grey; 
  user-select: none;

  width: 50%;
  justify-content: center;
  border: 1px solid grey;
  box-sizing: border-box;
  border-radius: 4px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-left: 8px;
  margin-right: 8px;
}

.ApproveButton {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  background-color: #165BC1; 
  color: white;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 4px 10px rgba(21, 76, 158, 0.24);
  
  width: 50%;
  justify-content: center;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}

.DisputeButton {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  display: flex;
  letter-spacing: 0.1px;
  background-color: #F7685B; 
  color: white;
  cursor: pointer;
  user-select: none;
  box-shadow: 0px 4px 10px rgba(21, 76, 158, 0.24);
  
  width: 50%;
  justify-content: center;
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
}