.Pill {
    border: 1px solid;
    padding: 1px 8px;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
    }
}