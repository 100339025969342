.SearchBox {
  width: 100%;
  height: 60px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.Search-magifying-glass {
  pointer-events: none;
  padding-left: 38.5px;
  padding-right: 15px;
  user-select: none;
}

.Search-text-input {
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */

  letter-spacing: 0.1px;

  color: #6C6C6C;
  outline: 0;
  border-width: 0 0 0px;
}