.Sidebar {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: black;
  z-index: 2;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  user-select: none;
}

.Sidebar-logo {
  height: 16px;
  width: 110px;
  pointer-events: none;
  user-select: none;
  padding-left: 24px;
  padding-top: 24px;
  padding-bottom: 20px;
}

.Sidebar-divider {
  height: 1px;
  width: 100%;
  background: #EBEFF2;
}

.Sidebar-item-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  color: #334D6E;
}

.Sidebar-item {
  height: 20px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  color: #334D6E;
}

.Sidebar-item:hover {
  color: #165BC1;
  filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(2071%) hue-rotate(209deg) brightness(82%) contrast(87%);
}

.Sidebar-item-selected {
  height: 20px;
  width: 100%;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  color: #165BC1;
  filter: brightness(0) saturate(100%) invert(24%) sepia(100%) saturate(2071%) hue-rotate(209deg) brightness(82%) contrast(87%);
}

.Sidebar-item-subitem {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  user-select: none;
  color: #334D6E;
}

.Sidebar-item-subitem-text {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #334D6E;
}

.Sidebar-item-circle {
  width: 8px;
  height: 8px;
  margin-right: 8px;
  margin-left: 56px;
  border-radius: 50%;  
  border: 2px solid #000000;
  box-sizing: border-box;
}

.Sidebar-item-image {
  margin-left: 26px;
  margin-right: 16px;
  -webkit-user-drag: none;
}

.Sidebar-item-text {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;

  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  color: inherit;
}