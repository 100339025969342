@import '../../styles/colors';

.FieldTicketsContainer {
  background-color: $background;
  padding: 24px;
  height: calc(100% - 64px - 48px);
}

.FieldTicketsTable {
  background-color: #FFFFFF;
  font-size: calc(10px + 2vmin);
  color: black;
  max-width: fit-content;
  box-shadow: 0px 2px 1px -1px RGB(0 0 0 / 20%), 0px 1px 1px 0px RGB(0 0 0 / 14%), 0px 1px 3px 0px RGB(0 0 0 / 12%);
}

.FieldTicketsTableHeaders {
  padding-left: 16px;
  min-width: 70px;
  border-bottom: 1px solid #EBEFF2;
}

.FieldTicketsTableHeaderBox {
  font-style: Bold;
  font-size: 13px;
  line-height: 24px;
  text-align: Left;
  vertical-align: Center;
  letter-spacing: 0.1px;
  height: 48px;
}

.FieldTicketsTableData {
  height: 64px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  text-align: left;
  letter-spacing: 0.1px;
  padding-left: 16px;
  padding-right: 16px;
  color: #111111;
}

.Divider { 
  width: 100%;
  height: 1px;
  background-color: black;
}

.FakeLinkStyle {
  cursor:pointer;
  color:blue;
  text-decoration:underline;
}

@media print  {
  .FieldTicketModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 100%;
    height: 100%;

    border: 1px solid #ccc;
    background: #FEFEFE;
    overflow: auto;
  
    outline: none;
    z-index: 200;
    zoom:58%;
    display: grid;
    grid-template-columns: 375px 1fr;
  }

  .OrderPanelPrint {
    display: none;
  }
}

@media screen {
  .FieldTicketModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
    width: 80%;
    height: 90%;
  
    border: 1px solid #ccc;
    background: #FEFEFE;
    overflow: auto;
  
    outline: none;
    z-index: 200;
  
    display: grid;
    grid-template-columns: 375px 1fr;
  }  
}

.FieldTicketModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
