.OriginalOrderPanel {
  background-color: #FEFEFE;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: black;

  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 32px;
  padding-right: 32px;
}

.OrderPanelHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height, or 150% */

  align-items: center;
  letter-spacing: 0.1px;
  /* Table / Black */
  margin-bottom: 24px;
  color: #323C47;
}

.OrderPanelPrint {
  color: #165BC1;
  cursor: pointer;
  float: right;
}

.OrderPanelSubheader {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  letter-spacing: 0.1px;
  margin-bottom: 16px;
  /* Neutral / Black */  
  color: #192A3E;
}

.OrderPanelItemTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: #111111;
}

.OrderPanelItemValue {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  
  color: #111111;
}

.Spacer {
  height: 16px;
}

.Order-divider {
  height: 1px;
  width: 100%;
  background: #EBEFF2;
  margin-top: 16px;
  margin-bottom: 16px;
}

.DualBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 16px;
}