@import '../../styles/colors';

.ListingsHeader {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ListingsHeaderSearch {
    padding: 12px 24px;
    width: 280px;
}

.ListingsHeaderButton {
    padding: 0 24px;
}

.ListingsContainer {
    background-color: $background;
    padding: 24px;
    height: calc(100% - 64px - 48px);
}

.ListingsTable {
    max-height: 100%;
}

.ListingsPillsContainer {
    display: flex;
    row-gap: 4px;
}

.ListingsActionLink {
    color: $blue-primary;
    text-decoration: underline;
    padding-right: 16px;
    cursor: pointer;

    &:last-child {
        padding-right: 0;
    }
}

.ListingsRowCompany {
    display: flex;
    align-items: center;
}

.ListingsRowCompanyImage {
    padding-right: 8px;
    height: 24px;
}

.EditListingFormContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 52px;
}

.EditListingForm {
    width: calc(100% - 48px);
    background-color: $white;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 5fr 70px 5fr;
}

.EditListingFormInput {
    padding: 16px 0;

    &.DualInput {
        display: grid;
        grid-template-columns: 5fr 5fr;
        column-gap: 24px;
    }

    &.SchedulingInput {
        padding: 8px 0;
    }
}

.EditListingSchedulingContainer {
    &:first-child {
        background-color: red;

        .EditListingScheduleDivider {
            display: none;
        }
    }
}

.EditListingScheduleDivider {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: 8px 0;

    .FormDividerHorizontal {
        width: 100%;
        border-top: 1px solid $grey-3;
    }
}

.EditListingFormDivider {
    height: calc(100% - 48px);
    display: flex;
    justify-content: center;
    align-self: center;

    .FormDividerInner {
        border-left: 1px solid $grey-3;
    }
}

.EditListingSection {
    padding: 0 24px;

    &:first-child {
        padding-top: 24px;
    }

    &:last-child {
        padding-bottom: 24px;
    }
}

.EditListingSectionHeader {
    font-weight: bold;
    font-size: 16px;
}

.EditListingSectionSubheader {
    font-weight: bold;
    font-size: 15px;
    display: flex;
    align-items: center;
}

.EditListingsContainer {
    background-color: $background;
}

.EditListingsHeader {
    background-color: $white;
    display: flex;
    justify-content: space-between;
    padding: 0 24px;
    height: 60px;
}

.EditListingsHeaderReturn {
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;

    svg {
        padding-right: 12px;
    }
}

.EditListingsHeaderButton {
    display: flex;
    align-items: center;
    padding: 12px 0;
}

.EditListingCancelButton {
    margin: 0 12px;
}

.EditListingCheckboxContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    padding: 7px 0;
}

.EditListingActionTextContainer {
    float: right;
    font-size: 14px;
    color: $blue-primary;
    text-decoration: underline;
    cursor: pointer;
}

.EditListingScheduleActionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
}

.EditListingScheduleActionButtons > Button:first-child {
    margin-right: 8px;
}

.EditListingAddAttachmentContainer {
    display: flex;
    justify-content: space-between;
}

.EditListingAddAttachmentPrompt {
    font-size: 12px;
    color: $blue-primary;
    cursor: pointer;
}

.EditListingAttachmentImageContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
}

.EditListingAttachmentImage {
    height: 80px;
    width: 80px;

    &:hover {
        transform: scale(5);
        transform-origin: top left;
    }
}

.EditListingMapContainer {
    padding: 24px 0;
}

.EditListingMapMarker {
    height: 24px;
    width: 24px;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #FFFFFF;
    border: 1px solid #FFFFFF;
    border-radius: 50%; 
}

.EditListingMapPopup {
    margin-bottom: 4px;
}

.EditListingNavContainer {
    height: 60px;
    width: 100%;
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        color: $grey-6;
        font-size: 16px;
    }
}

.EditListingNavItem {
    width: 209px;
    height: 56px;
    border-bottom: 1px solid $grey-4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.active {
        border-color: $blue-primary;
        border-width: 2px;
        font-weight: bold;
    }
}

.EditListingNavStage {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue-primary;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background-color: $blue-lightest;
    color: $blue-primary;
    margin-right: 8px;
}

.EditListingScheduleCancel {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.EditListingScheduleCancelText {
    position: relative;
    cursor: pointer;
    color: red;
    font-size: 14px;
}

.BidsPrompt {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: center;
    padding: 40px 0;
}

.BidsContainer {
    background-color: $white;
    margin: 0 24px 24px 24px;
}

.BidsHaulerContainer {
    // display: grid;
    // grid-template-columns: 5fr 70px 5fr;
    display: flex;
    justify-content: center;
}

.BidsHaulerSection {
    padding: 24px;
    width: 100%;
    max-width: 800px;
}

.BidsHaulerHeader {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding-bottom: 16px;
}

.BidsHaulerChargeNameInput {
    width: 360px;
}

.BidsHaulerCurrencyInput {
    input {
        padding: 10.5px 16px !important;
    }
}

.BidsHaulerDeleteCharge {
    cursor: pointer;
    color: $red;
    padding-left: 8px;
}

.BidsHaulerChargeInputContainer {
    display: flex;
    align-items: center;
}

.BidsHaulerSectionBar {
    height: calc(54px - 32px);
    display: flex;
    justify-content: space-between;
    padding: 16px;

    &.Header {
        font-weight: 700;
        background-color: $blue-primary;
        color: $white;
    }

    &.Footer {
        background-color: $grey-3;
        font-weight: 700;
        height: unset;
        display: flex;
        align-items: center;
    }

    &.Input {
        display: flex;
        align-items: center;
        height: unset;
    }
}

.BidsHaulerSiteContainer {
    width: 230px;
    min-width: 0;
}

.BidsHaulerSectionBarAction {
    text-decoration: underline;
    cursor: pointer;
}

.BidsHaulerSectionFootnote {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
}

.BidsHaulerSectionAssignment {
    background-color: $grey-1;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;;
}

.BidsHaulerSectionSlider {
    margin-right: 24px;
}

.BidsHaulerSectionAssignmentInput {
    display: flex;
    align-items: center;
}

.BidsHaulerSectionActionRow {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
}

.BidsHaulerSectionActions {
    display: flex;
    justify-content: flex-end;
}

.BidsHaulerCannotChangePrompt {
    display: flex;
    align-items: center;
    padding-right: 16px;
    font-size: 14px;
    font-weight: 700;
}

.BidsHaulerSave {
    margin-left: 24px;
}

.BidsCardContainer {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(360px, 1fr) );
    column-gap: 24px;
    row-gap: 24px;
    padding: 0 24px 24px 24px;
}

.BidContainer {
    width: 360px;
    border: 1px solid $grey-4;
}

.BidsHeader {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    padding: 24px;
}

.BidsEmpty {
    font-size: 24px;
    font-weight: 700;
    height: 360px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BidCardName {
    height: 52px;
    display: flex;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    align-items: center;
    border-bottom: 1px solid $grey-4;
}

.BidCardGroupIcon {
    padding: 0 12px;
    height: 24px;
    width: 24px;
}

.BidCardDetails {
    padding: 16px 16px 0 16px;
}

.BidCardItem {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between;
    border-bottom: 1px solid $grey-4;
    font-size: 14px;
    line-height: 22px;
}

.BidCardTotal {
    height: 56px;
    font-size: 16px;
    font-weight: 700;
}

.BidCardTotalValue {
    color: $blue-primary;
}

.BidCardInput {
    padding: 8px 0;
}

.BidCardInputHeader {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 4px;
}

.BidCardInputContent {
    display: grid;
    grid-template-columns: 180px 90px;
    padding-left: 8px;
    column-gap: 24px;
    align-items: center;
}

.BidCardAvailableText {
    font-weight: 700;
    font-size: 14px;
}

.BidCardActions {
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-primary;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    border-top: 1px solid $grey-4;
    cursor: pointer;
}

.ListingJobsContainer {
    margin: 0 24px 24px 24px;
    height: 100%;
    max-height: calc(100% - 176px);
    overflow-y: scroll;
    background-color: $white;
}

.ListingJobsNoActiveJobs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
}

.ListingJobsNav {
    display: inline-block;
    cursor: pointer;
    margin-right: 2px;
}

.ListingJobGroupIcon {
    height: 24px;
    width: 24px;
    padding-right: 8px;
}

.ListingJobsNavCell {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $grey-4;
    border-bottom: none;
    font-size: 14px;
    line-height: 22px;
    padding: 8px 16px;
    background-color: $grey-1;
}

.ListingJobsNavCellActive {
    background-color: $white;
}

.ListingJobsCardContainer {
    display: grid;
    grid-template-columns: repeat( auto-fill, minmax(360px, 1fr) );
    column-gap: 24px;
    row-gap: 24px;
    padding: 24px;
    border-top: 1px solid $grey-4;
}

.ListingJobsCard {
    width: 360px;
    border: 1px solid $grey-4;
}

.ListingJobsCardHeader {
    padding: 16px 0 16px 16px;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    display: flex;
    justify-content: space-between;
}

.ListingJobsCardHeaderLink {
    font-size: 16px;
    font-weight: 700;
    color: $blue-primary;
    cursor: pointer;
    padding-right: 16px;
}

.ListingJobsCardSection {
    padding: 16px;
    border-top: 1px solid $grey-4;
}

.ListingJobsCardTopSection {
    margin-top: 8px;

    &:first-child {
        margin-top: 0;
    }
}

.ListingJobsCardTopSectionHeader {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: $grey-7;
    padding-bottom: 4px;
}

.ListingJobsCardTopSectionContent {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.ListingJobsCardTopSectionPill {
    color: $blue-primary;
    line-height: 20px;
    font-size: 12px;
    font-weight: 700;
    background-color: $grey-2;
    padding: 0 8px;
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
    }
}

.ListingJobsCardBottomSection {
    display: flex;
    align-items: flex-start;
}

.ListingJobsCardBottomSectionDetails {
    padding-left: 16px;
}

.ListingJobsCardBottomSectionDetailsPrimary {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

.ListingJobsCardBottomSectionDetailsSecondary {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $grey-7;
}

.ListingJobsCardActions {
    display: flex;
    justify-content: center;
    // grid-template-columns: repeat( auto-fill, minmax(360px, 1fr) );
    height: 40px;
    border-top: 1px solid $grey-4;
}

.ListingJobsCardAction {
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-primary;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 0;
    border-right: 1px solid $grey-4;

    &:last-child {
        border-right: none;
    }
}

.mapboxgl-control-container {
    display: none; 
}
