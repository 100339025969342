
.SectionSelectorsBox {
  height: 60px;
  background-color: white;
  color: black;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  max-width: fit-content;
  min-width: min-content;
  border-radius: 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin: 24px;
  overflow: hidden;
}

.SectionSelector-item {
  width: 145px;
  height: 60px;
  border-radius: 30px;

  color: black;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  /* identical to box height, or 150% */
  letter-spacing: 0.1px;
  cursor: pointer;
  user-select: none;

  color: #6C6C6C;
  border-bottom: 2px solid transparent;
}

.SectionSelector-item-selected {
  width: 145px;
  height: 60px;
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  letter-spacing: 0.1px;
  text-align: Left;
  vertical-align: Center;
  letter-spacing: 0.1p;
  user-select: none;
mask-border: true;
  color: #165BC1;
  border-bottom: 4px solid #165BC1;
}

.SectionSelector-arrow {
  pointer-events: none;
  margin-right: 8px;
  margin-left: 16px;
  width: 20px;
  height: 20px;
  user-select: none;
}

.BottomHighlightBar {
  pointer-events: none;
  width: 100%;
  height: 20px;
  user-select: none;
  background-color: #165BC1;
}