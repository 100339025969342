$font-size-standard: 14px;
$font-size-large: 16px;
$font-size-heading: 24px;

$line-height-standard: 22px;
$line-height-large: 24px;
$line-height-heading: 32px;

.bold {
    font-weight: 700
}

.font--xl {
    font-size: 24px;
}

.font--large {
    font-size: 16px;
}

.font--standard {
    font-size: 14px;
}

.font--small {
    font-size: 12px;
}