@import '../../styles/colors';

.ActiveJobsContainer {
    margin: 24px;
    height: 100%;
    max-height: calc(100% - 108px - 48px);
    overflow-y: scroll;
    background-color: $white;
    padding: 24px;
}

.ActiveJobsLoading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.ActiveJobsNoJobs {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 24px;
    font-weight: 700;
}

.ActiveJobsDateHeader {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    padding-bottom: 24px;
}

.ActiveJobsCardHeader {
    padding: 16px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ActiveJobCardHeaderCompany {
    display: flex;
    align-items: center;
    line-height: 32px;
    font-size: 24px;
    font-weight: 700;
}

.ActiveJobsCardHeaderLink {
    font-size: 16px;
    font-weight: 700;
    color: $blue-primary;
    cursor: pointer;
}