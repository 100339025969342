.Box {
  width: 100%;
  height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #FFFFFF;
  box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
}

.FillBox {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}