@import '../../styles/colors';
@import '../../styles/typography';

.NavContainer {
    width: 256px;
    height: 100%;
    background-color: $background-1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
}

.NavHeader {
    padding: 24px 0 24px 24px;

    img {
        height: 20px;
    }
}

.NavSectionContainer {
    height: 100%;
    color: $white;
}

.NavCategory {
    padding-left: 24px;
    opacity: .65;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.active {
        opacity: 1;   
    }
}

.NavCategoryTitle {
    display: flex;
    align-items: center;

    .NavCategoryIcon {
        font-size: 16px;
    }

    .NavCategoryString {
        padding-left: 8px;
        font-size: $font-size-standard;
        line-height: $line-height-standard;
    }
}

.NavSectionExpandIcon {
    font-size: 16px;
    padding-right: 24px;
}

.NavSubcategoryContainer {
    background-color: $background-2;
}

.NavSubcategory {
    display: flex;
    align-items: center;
    padding-left: 32px;
    height: 40px;
    font-size: $font-size-standard;
    line-height: $line-height-standard;
}


.NavSubcategoryItem {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    opacity: .65;
    font-size: $font-size-standard;
    line-height: $line-height-standard;

    &.active {
        opacity: 1;
        background-color: $blue-primary;
    }
}

.NavLink {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding-left: 48px;
}


.NavFooter {
    height: 48px;
    color: white;
    background-color: $background-2;
    cursor: pointer;
}

.NavFooterButton {
    width: 100%;
    height: 100%;
}

.NavFooterLogoutIcon {
    padding: 0 4px;
}