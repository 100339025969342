.AdminPage {
    width: 100%;
    background-color: #F5F6F8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: calc(10px + 2vmin);
    color: black;
    z-index: 1;
  }