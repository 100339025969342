@import '../../styles/colors';

.LoginHeader {
    height: 64px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
}

.LoginLogo {
    height: 20px;
    padding: 22px 24px;
}

.LoginPageContainer {
    background-color: #E5E5E5;
    height: calc(100% - 64px);
    display: flex;
    justify-content: center;;
}

.LoginContainer {
    background-color: #FFFFFF;
    height: 312px;
    width: 400px;
    margin-top: 96px;

    form {
        padding: 32px 40px;
    }
}

.RegisterContainer {
    background-color: #FFFFFF;
    height: 432px;
    width: 400px;
    margin-top: 96px;

    form {
        padding: 32px 40px;
    }
}

.LoginHeading {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    padding-bottom: 16px;
}

.LoginFormInput {
    padding-top: 24px;
}

.LoginFormActions {
    padding-top: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    a {
        color: $blue-primary;
        font-size: 14px;
        padding-right: 24px;
        cursor: pointer;
    }
}

.LoginTermsLink {
    color: $blue-primary;
}