$blue-primary: #165BC1;
$blue-lightest: #CEDCF0;

$background: #F0F2F5;
$background-1: rgba(0, 21, 41, 1);
$background-2: rgba(0, 12, 23, 1);

$white: #FFFFFF;

$grey-1: #FAFAFA;
$grey-2: #F5F5F5;
$grey-3: #F0F0F0;
$grey-4: #D9D9D9;
$grey-6: #8C8C8C;
$grey-7: #595959;

    
$red: #CF1322;